import {useEffect, useState} from "react";
import SignInSwitcher from "./SignInSwitcher";
import SignUp from "../SignUp/SignUp";
import SignIn from "./SignIn";
import Logo from "../../icons/favicon.png";
import {useAppSelector} from "../../store/hooks";
import MultiLoginsMessage from "./MultiLoginsMessage";
import {useSearchParams} from "react-router-dom";

export enum SwitcherState {
    Login = "login",
    SignUp = "signup",
}

const SignPage = () => {
    const [searchParams] = useSearchParams();
    const [switcherState, setSwitcherState] = useState(
        searchParams.get("active") ?? SwitcherState.SignUp
    );
    const [displayMsg, setDisplayMsg] = useState(false);

    useEffect(() => {
        setDisplayMsg(searchParams.get("loggedOut") === "true");
    }, [searchParams]);

    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    return (
        <div className={`${lightMode} sign-page-container`}>
            {displayMsg && switcherState !== SwitcherState.SignUp && (
                <MultiLoginsMessage/>
            )}
            <img
                src={Logo}
                className={`${lightMode} uxmaps-logo sign-page`}
                alt={"uxmaps-logo"}
            />
            <SignInSwitcher
                setSwitcher={(switchState) => {
                    searchParams.set("active", switchState.toString());
                    setSwitcherState(switchState);
                }}
                switchState={switcherState}
            />
            {switcherState === SwitcherState.SignUp ? (
                <SignUp
                    setSwitcher={(switchState) => {
                        searchParams.set("active", switchState.toString());
                        setSwitcherState(switchState);
                    }}
                />
            ) : (
                <SignIn/>
            )}
        </div>
    );
};

export default SignPage;
