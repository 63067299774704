import "./Screen.css";
import {useEffect, useRef, useState} from "react";
import {Screen as ScreenType} from "../../../utils/types/screen";
import {useAppSelector} from "../../../store/hooks";
import {StyledScreenImage} from "../../common/ProductPage/ProductPage.styles";
import LockImg from "../../../icons/lock.svg";
import LockImgDark from "../../../icons/lock-dark.svg";
import UpgradePlanModal from "../../UpgradePlanModal/UpgradePlanModal";
import LockedIconCompany from "../../../icons/LockScreenIcon.png";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";

interface ScreenProps {
    screen: ScreenType;
    companyIcon: string;
    companyName: string;
    collectionChangedFunc: (
        screenId: string,
        collectionId: string,
        type: string
    ) => void;
    onClicked: () => void;
    selectingScreens?: (id: string) => void;
    selectingMode?: boolean;
}

const Screen = ({
                    screen,
                    companyIcon,
                    companyName,
                    onClicked,
                    selectingScreens,
                    selectingMode,
                }: ScreenProps) => {
    const areFiltersOpen = useAppSelector(
        (state) => state.filters.isFilterOpened
    );
    const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
        useState<boolean>(false);
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const checkboxRef: any = useRef();
    const [innerWidth, setInnerWidth] = useState<number>(
        document.documentElement.clientWidth
    );

    const handleResize = () => {
        setInnerWidth(document.documentElement.clientWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (isUpgradePlanModalOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isUpgradePlanModalOpened]);

    const {isMobile} = useIsMobile();

    return (
        <div
            className={`${lightMode} item-${screen.id} company item`}
            style={screen.locked ? {cursor: "default"} : {}}
            onClick={onClicked}
        >
            <UpgradePlanModal
                onClose={() => setIsUpgradePlanModalOpened(false)}
                isOpen={isUpgradePlanModalOpened}
                type="limit"
            />
            <input
                type="checkbox"
                ref={checkboxRef}
                className={`checkbox ${selectingMode === true ? "" : "hide-checkbox"}`}
                onClick={(e) => {
                    e.stopPropagation();
                    if (selectingScreens) {
                        selectingScreens(screen.id);
                    }
                }}
            />
            <StyledScreenImage
                isMobile={isMobile}
                areFiltersOpen={areFiltersOpen}
                innerWidth={innerWidth}
                style={
                    !screen.locked
                        ? {
                            backgroundImage: `url(${
                                screen.presignedURLResized ?? screen.presignedURL
                            })`,
                        }
                        : lightMode === "light"
                            ? {
                                backgroundColor: "#EAECF0",
                            }
                            : {
                                backgroundColor: "#1E1E1E",
                            }
                }
                className={`${lightMode} screen-image with-border`}
            >
                {screen.locked && (
                    <button
                        className={`${lightMode} purchase-plan-locked screen-flow-locked`}
                        onClick={() => setIsUpgradePlanModalOpened(true)}
                    >
                        <img
                            src={lightMode === "light" ? LockImg : LockImgDark}
                            alt={"locked"}
                        />
                        Upgrade to see this shot
                    </button>
                )}
            </StyledScreenImage>

            <div className={`${lightMode} company-info`}>
                <img
                    src={screen.locked ? LockedIconCompany : companyIcon}
                    alt={"company-image"}
                />
                <div className={`${lightMode} screen-text`}>
                    <h3 className={`${lightMode} screen-name`}>{screen.name}</h3>
                    <h3 className={`${lightMode} screen-company`}>{companyName}</h3>
                </div>
            </div>
        </div>
    );
};
export default Screen;
