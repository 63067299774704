import { Fetch, FetchInput, Method } from "../../Fetch";

export interface UpdatePasswordRequest {
  oldPassword: string;
  newPassword: string;
  signal?: AbortSignal;
}

export interface UpdatePasswordResponse {
  error: string;
}

export async function UpdatePassword(
  request: UpdatePasswordRequest
): Promise<UpdatePasswordResponse> {
  const UpdatePasswordURL = "/me/password";
  const requestBody = JSON.stringify({
    oldPassword: request.oldPassword,
    newPassword: request.newPassword,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: UpdatePasswordURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          error: "",
        };
      }

      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
