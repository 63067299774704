import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

interface Nav {
    projectId: string;
    boardId: string;
    isDynamicOpen: boolean;
    isFilterSideBarOpen: boolean;
    isPreviewScreenOpen: boolean;
    history: Array<string>;
    browserBackButton: boolean;
    shouldBlockKeyboardNavigation: boolean;
    lightMode: string;
    shouldDisableFilters: boolean;
    limitExceeded: boolean;
    lockLimit: boolean;
}


const initialState: Nav = {
    projectId: "",
    boardId: "",
    isDynamicOpen: false,
    isFilterSideBarOpen: false,
    isPreviewScreenOpen: false,
    history: [],
    browserBackButton: false,
    shouldBlockKeyboardNavigation: false,
    lightMode: localStorage.getItem("lightingMode") || "light",
    shouldDisableFilters: false,
    limitExceeded: false,
    lockLimit: false,
};

export const navigationSlice = createSlice({
    name: "navigationSlice",
    initialState,
    reducers: {
        setIfLimitIsExceeded: (state, action: PayloadAction<boolean>) => {
            state.limitExceeded = state.lockLimit === true ? false : action.payload;
        },
        setLightMode: (state, action: PayloadAction<string>) => {
            state.lightMode = action.payload;
        },
        setBoardId: (state, action: PayloadAction<string>) => {
            state.boardId = action.payload;
        },
        setProjectId: (state, action: PayloadAction<string>) => {
            state.projectId = action.payload;
        },
        setDynamicIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isDynamicOpen = action.payload;
        },
        setIsFilterSideBarOpen: (state, action: PayloadAction<boolean>) => {
            state.isFilterSideBarOpen = action.payload;
        },
        setIsPreviewScreenOpen: (state, action: PayloadAction<boolean>) => {
            state.isPreviewScreenOpen = action.payload;
        },
        historyPush: (state, action: PayloadAction<string>) => {
            state.history = [...state.history, action.payload];
        },
        historyPop: (state) => {
            state.history = state.history.slice(0, -1);
        },
        historyBackButtonHandle: (state) => {
            state.history =
                state.history[state.history.length - 1] === window.location.pathname
                    ? state.history.slice(0, -2)
                    : [...state.history];
        },
        setShouldBlockKeyboardNavigation: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.shouldBlockKeyboardNavigation = action.payload;
        },
        setShouldDisableFilters: (state, action: PayloadAction<boolean>) => {
            state.shouldDisableFilters = action.payload;
        },
        setLockLimit: (state) => {
            state.lockLimit = true;
        },
    },
});

export const {
    setBoardId,
    setProjectId,
    setDynamicIsOpen,
    setIsFilterSideBarOpen,
    setIsPreviewScreenOpen,
    historyPop,
    historyPush,
    historyBackButtonHandle,
    setShouldBlockKeyboardNavigation,
    setLightMode,
    setShouldDisableFilters,
    setIfLimitIsExceeded,
    setLockLimit,
} = navigationSlice.actions;

export default navigationSlice.reducer;
