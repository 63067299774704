import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

let timerId: any;
const initialState: any = {
  notification: "",
  isActive: false,
  isErrNotification: false,
};

const wait = (ms: number) =>
  new Promise<void>((resolve) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => resolve(), ms);
  });

export const asyncNotification = createAsyncThunk(
  "notification/asyncNotification",
  async (notification: any) => {
    if (
      notification.notification &&
      typeof notification.notification === "string"
    ) {
      await wait(10 * 1000);
    } else {
      await wait(4 * 1000);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    closeNotification: (state) => {
      state.isActive = false;
      state.notification = "";
      state.isErrNotification = false;
    },
    setNotification: (state, action: PayloadAction<any>) => {
      state.notification = action.payload.notification;
      state.isActive = true;
      state.isErrNotification = action.payload.isErrNotification;
    },
  },
  extraReducers: (builder: any) =>
    builder
      // log in when thunk is initiated
      .addCase(asyncNotification.pending, (state: any, action: any) => {
        return action.meta.arg;
      })
      // log out when thunk finally resolves
      .addCase(asyncNotification.fulfilled, (state: any, action: any) => {
        // only log out if this user is still the logged in user
        if (state.isActive) {
          setNotification(initialState);
          return initialState;
        }
      })
      .addCase(asyncNotification.rejected, (state: any, action: any) => {
        setNotification(initialState);
        return initialState;
      }),
});

export const { setNotification, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
