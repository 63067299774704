import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

interface Filters {
    isFilterOpened: boolean;
    activeFilters: string[];
    searchFilter: String;
}

const initialState: Filters = {
    isFilterOpened: false,
    activeFilters: [],
    searchFilter: "",
};

export const filtersSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        shouldFiltersOpen: (state) => {
            state.isFilterOpened = !state.isFilterOpened;
        },
        setActiveFilters: (state, action: PayloadAction<any>) => {
            state.activeFilters = action.payload;
        },
        resetActiveFilters: (state) => {
            state.activeFilters = initialState.activeFilters;
        },
        setSearchFilter: (state, action: PayloadAction<String>) => {
            state.searchFilter = action.payload;
        },
        setIsFilterSideBarOpen: (state, action: PayloadAction<boolean>) => {
            state.isFilterOpened = action.payload;
        },
    },
});

export const {
    shouldFiltersOpen,
    setActiveFilters,
    resetActiveFilters,
    setSearchFilter,
    setIsFilterSideBarOpen,
} = filtersSlice.actions;

export default filtersSlice.reducer;
