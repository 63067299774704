import "./Filters.css";
import {ChangeEvent, useCallback, useEffect, useRef, useState,} from "react";
import searchFilters from "../../icons/SearchFilters.svg";
import searchFiltersWhite from "../../icons/SearchFiltersWhite.svg";
import refresh from "../../icons/refresh.svg";
import chavronFilters from "../../icons/chavronFilters.svg";
import close from "../../icons/closeFilters.svg";
import refreshWhite from "../../icons/refreshWhite.svg";
import chavronFiltersWhite from "../../icons/chavronFiltersWhite.svg";
import closeWhite from "../../icons/closeWhite.svg";
import FiltersDesignElements from "../../icons/FiltersDesignElements.svg";
import FiltersIndustry from "../../icons/FiltersIndutry.svg";
import FiltersCompanies from "../../icons/FiltersCompanies.svg";
import FiltersDesignElementsWhite from "../../icons/FiltersDesignElementsWhite.svg";
import FiltersIndustryWhite from "../../icons/FiltersIndutryWhite.svg";
import FiltersCompaniesWhite from "../../icons/FiltersCompaniesWhite.svg";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    resetActiveFilters,
    setActiveFilters,
    setIsFilterSideBarOpen as filtersSliceOpen,
} from "../../store/slice/filtersSlice";
import {setIsFilterSideBarOpen, setShouldBlockKeyboardNavigation,} from "../../store/slice/navigationSlice";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import FiltersDropdowns from "./FiltersDropdowns";
import {StyledFiltersContainer} from "../common/ProductPage/ProductPage.styles";
import {Tag} from "../../utils/types/tag";
import debounce from "lodash/debounce";
import ModesImg from "../../icons/modes-filters.svg";
import ModesImgDark from "../../icons/modes-filters-dark.svg";
import {Screen} from "../../utils/types/screen";
import FiltersDropdownsModes from "./FiltersDropdownsModes";
import {TagTypeWithTags} from "../../utils/types/tagType";
import {useIsMobile} from "../../utils/hooks/useIsMobile";

interface FiltersProps {
    displaysFrom: string;
    screens?: Screen[] | null;
}

const Filters = ({displaysFrom, screens}: FiltersProps) => {
    const allTagTypesWithTags = useAppSelector(
        (state) => state.tags.allTagTypesWithTags
    );
    const [searchValue, setSearchValue] = useState("");
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const shouldDisplayFilters: boolean = useAppSelector(
        (state) => state.filters.isFilterOpened
    );
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const [selectedTags, setSelectedTags] = useState<string[]>(
        searchParams.get("tagList")?.split(",") || []
    );
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isDropdownOpen, setIsDropDownOpen] = useState({
        designElements: false,
        industry: false,
        companies: false,
        modes: false,
    });
    const [areListenersAdded, setAreListenersAdded] = useState<boolean>();
    const [displayRules, setDisplayRules] = useState<Array<string>>();
    const [shouldDebounce, setShouldDebounce] = useState<boolean>();
    const activeFilters = useAppSelector((state) => state.filters.activeFilters);
    const navigate = useNavigate();
    const location = useLocation();
    let screenArray: string[] = [];
    if (screens)
        screens.forEach((scr) =>
            scr.tagList.forEach((s) => {
                if (!screenArray.includes(s.id)) {
                    screenArray.push(s.id);
                }
            })
        );
    const lightningMode: Tag[] | undefined = useAppSelector(
        (state) =>
            state.tags.allTagTypesWithTags.find(
                (tagType: TagTypeWithTags) => tagType.tagType.name === "Mode"
            )?.tagList
    );

    const modeLight = lightningMode ? lightningMode.find((mode) =>
        mode.name.toLowerCase().includes("light")
    )?.id : undefined;
    const darkMode = lightningMode ? lightningMode.find((mode) =>
        mode.name.toLowerCase().includes("dark")
    )?.id : undefined;

    useEffect(() => {
        setSelectedTags([]);
        if (
            window.location.pathname.includes("/Navigation/Flows/FlowInfo") ||
            window.location.pathname.includes("/Navigation/ProfileSettings") ||
            window.location.pathname.includes("/Navigation/BoardSettings")
        ) {
            setDisplayRules([]);
        } else if (
            window.location.pathname.includes("/Navigation/Flows") ||
            window.location.pathname.includes("/Navigation/Screens")
        ) {
            setDisplayRules(
                window.location.pathname.includes("/Navigation/Screens")
                    ? ["Company", "Industry", "Element", "Modes"]
                    : ["Company", "Industry", "Element"]
            );
            setShouldDebounce(true);
        } else if (
            window.location.pathname.includes("/Navigation/Design/Element")
        ) {
            setDisplayRules(["Company", "Industry", "Modes"]);
            setIsDropDownOpen((prevState) => {
                return {...prevState, companies: true};
            });
        } else if (
            window.location.pathname.includes("Navigation/Companies/CompanyInfo") ||
            window.location.pathname.includes("/Navigation/Design")
        ) {
            setDisplayRules(
                window.location.pathname.includes("Navigation/Companies/CompanyInfo")
                    ? ["Element", "Modes"]
                    : ["Element"]
            );
            setIsDropDownOpen((prevState) => {
                return {...prevState, designElements: true};
            });
        } else if (window.location.pathname.includes("Navigation/Companies")) {
            setDisplayRules(["Company", "Industry"]);
            setIsDropDownOpen((prevState) => {
                return {...prevState, companies: true};
            });
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        setSelectedTags(searchParams.get("tagList")?.split(",") || []);
    }, [searchParams]);

    const ref = useRef<HTMLDivElement>(null);

    const closeFilters = useCallback(() => {
        setIsSearching(false);
        dispatch(setIsFilterSideBarOpen(false));
        dispatch(filtersSliceOpen(false));
    }, [dispatch]);

    useEffect(() => {
        const handleHideDropdown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                dispatch(setShouldBlockKeyboardNavigation(false));
                closeFilters();
            }
        };

        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                dispatch(setIsFilterSideBarOpen(false));
            }
        };

        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);

        setAreListenersAdded(true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [areListenersAdded, closeFilters, dispatch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (value) => {
                dispatch(setActiveFilters(value));
            },
            1000,
            {leading: false, trailing: true}
        ),
        []
    );

    const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const setSelectedTagsCb = (tag: string) => {
        if (tag !== "light" && tag !== "dark") {
            if (selectedTags.includes(tag)) {
                const selectedNew = selectedTags.filter((tagId) => tagId !== tag);
                setSelectedTags(selectedNew);
                if (shouldDebounce) debouncedSearch(selectedNew);
                else dispatch(setActiveFilters(selectedNew));
            } else {
                setSelectedTags([...selectedTags, tag]);
                if (shouldDebounce) debouncedSearch([...selectedTags, tag]);
                else dispatch(setActiveFilters([...selectedTags, tag]));
            }
        } else {
            if (tag === "light" && modeLight && !selectedTags.includes(modeLight)) {
                const filteredTags = selectedTags.filter((tag) => tag !== darkMode);
                filteredTags.push(modeLight);

                setSelectedTags(filteredTags);
                if (shouldDebounce) debouncedSearch(filteredTags);
                else dispatch(setActiveFilters(filteredTags));
            } else if (tag === "dark" && darkMode && !selectedTags.includes(darkMode)) {
                const filteredTags = selectedTags.filter((tag) => tag !== modeLight);
                filteredTags.push(darkMode);

                setSelectedTags(filteredTags);
                if (shouldDebounce) debouncedSearch(filteredTags);
                else dispatch(setActiveFilters(filteredTags));
            } else {
                if (tag === "light") {
                    const filteredTags = selectedTags.filter((tag) => tag !== modeLight);

                    setSelectedTags(filteredTags);
                    if (shouldDebounce) debouncedSearch(filteredTags);
                    else dispatch(setActiveFilters(filteredTags));
                }
                if (tag === "dark") {
                    const filteredTags = selectedTags.filter((tag) => tag !== darkMode);

                    setSelectedTags(filteredTags);
                    if (shouldDebounce) debouncedSearch(filteredTags);
                    else dispatch(setActiveFilters(filteredTags));
                }
            }
        }
    };

    const resetFilters = () => {
        dispatch(resetActiveFilters());
        if (searchParams.get("tagList")) {
            searchParams.delete("tagList");

            const newSearchString = searchParams.toString();
            navigate({
                pathname: location.pathname,
                search: newSearchString ? `?${newSearchString}` : "",
            });
        }

        setSelectedTags([]);
    };

    useEffect(() => {
        dispatch(setShouldBlockKeyboardNavigation(isSearchFocused));
    }, [dispatch, isSearchFocused]);

    const countScreens = (type: string) => {
        let screenNumber;

        if (!screens) {
            screenNumber = allTagTypesWithTags
                .find((t) => t.tagType.name === type)
                ?.tagList?.filter((el: Tag) =>
                    el.name
                        .toLowerCase()
                        .split(" ")
                        .map((word: string) => word.startsWith(searchValue.toLowerCase()))
                        .some((startsWithSearchValue: boolean) => startsWithSearchValue)
                ).length;
        } else {
            screenNumber = allTagTypesWithTags
                .find((t) => t.tagType.name === type && screens)
                ?.tagList?.filter(
                    (el: Tag) =>
                        screenArray.includes(el.id) &&
                        el.name
                            .toLowerCase()
                            .split(" ")
                            .map((word: string) => word.startsWith(searchValue.toLowerCase()))
                            .some((startsWithSearchValue: boolean) => startsWithSearchValue)
                ).length;
        }
        return screenNumber;
    };

    const generateTagList = (type: string) => {
        const tagList: Array<Tag> = [];
        if (!screens) {
            return (
                allTagTypesWithTags.find((t) => t.tagType.name === type)?.tagList || []
            );
        }
        allTagTypesWithTags.forEach((t: TagTypeWithTags) => {
            if (t.tagType.name === type && screens && screens.length > 0) {
                t.tagList.forEach((el: Tag) => {
                    if (screenArray.includes(el.id)) tagList.push(el);
                });
            }
        });
        return tagList;
    };
    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const {isMobile} = useIsMobile();

    useEffect(() => {
        if (isMobile && shouldDisplayFilters) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [isMobile, shouldDisplayFilters]);

    return (
        <>
            {shouldDisplayFilters && displayRules?.length !== 0 && (
                <StyledFiltersContainer
                    lightMode={lightMode}
                    displayFilters={shouldDisplayFilters}
                    displaysFrom={displaysFrom}
                    isScrolled={0}
                    ref={ref}
                    isMobile={isMobile}
                >
                    <div
                        className={`${lightMode} filters-search-container`}
                        style={
                            isSearching && lightMode === "light"
                                ? {border: "0.5px solid #EAECF0"}
                                : isSearching && lightMode === "dark"
                                    ? {border: "0.5px solid #3f3f3f"}
                                    : {}
                        }>
                        <img
                            src={lightMode === "light" ? searchFilters : searchFiltersWhite}
                            style={{marginRight: "8px", cursor: "pointer"}}
                            onClick={() => setIsSearching((prev) => !prev)}
                            alt={"searchFilters"}
                        />
                        {!isSearching ? (
                            <>
                                Filters
                                <div className={`${lightMode} filters-img-sector`}>
                                    <div className={`${lightMode} filters-img-container`}>
                                        <img
                                            className={`${lightMode} filters-search-icon-right`}
                                            src={
                                                lightMode === "light"
                                                    ? chavronFilters
                                                    : chavronFiltersWhite
                                            }
                                            onClick={() => closeFilters()}
                                            alt={"chavronFilters"}
                                        />
                                    </div>
                                    {selectedTags.length > 0 && (
                                        <div
                                            className={`${lightMode} filters-img-container`}
                                            onClick={() => resetFilters()}
                                            style={
                                                selectedTags.length > 0 ? {marginRight: "5px"} : {}
                                            }>
                                            <img
                                                src={lightMode === "light" ? refresh : refreshWhite}
                                                alt="refresh-icon"
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <input
                                    autoFocus
                                    className={`${lightMode} filters-search`}
                                    value={searchValue}
                                    onChange={(e) => handleSearchValueChange(e)}
                                    onFocus={() => {
                                        setIsSearchFocused(true);
                                    }}
                                    onBlur={() => setIsSearchFocused(false)}
                                />{" "}
                                <div className={`${lightMode} filters-img-sector`}>
                                    <div className={`${lightMode} filters-img-container`}>
                                        <img
                                            className={`${lightMode} filters-search-icon-right`}
                                            onClick={() => {
                                                setSearchValue("");
                                                setIsSearching((prev) => !prev);
                                            }}
                                            src={lightMode === "light" ? close : closeWhite}
                                            alt={"close"}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={`${lightMode} filters-elements-container`}>
                        {displayRules?.includes("Element") &&
                            generateTagList("Design elements").length > 0 && (
                                <div className={`${lightMode} filters-design-elements`}>
                                    <div
                                        className={`${lightMode} filters-design-elements-header`}
                                        style={
                                            isDropdownOpen.designElements
                                                ? {
                                                    backgroundColor:
                                                        lightMode === "light" ? "#f9fafb" : "#303030",
                                                }
                                                : {}
                                        }
                                        onClick={() =>
                                            setIsDropDownOpen((prev) => {
                                                return {
                                                    ...prev,
                                                    designElements: !prev.designElements,
                                                };
                                            })
                                        }>
                                        <img
                                            src={
                                                lightMode === "light"
                                                    ? FiltersDesignElements
                                                    : FiltersDesignElementsWhite
                                            }
                                            style={{marginRight: "17px", width: "17px"}}
                                            alt={"FiltersDesignElements"}
                                        />
                                        Design Elements
                                        <p className={`${lightMode} filters-numbers`}>
                                            {countScreens("Design elements")}
                                        </p>
                                    </div>
                                    {isDropdownOpen.designElements && (
                                        <FiltersDropdowns
                                            modes={false}
                                            searchValue={searchValue}
                                            selectedTags={selectedTags}
                                            setSelectedTagsCb={(tag: string) =>
                                                setSelectedTagsCb(tag)
                                            }
                                            tagList={generateTagList("Design elements")}
                                            tagType="Design elements"
                                        />
                                    )}
                                </div>
                            )}
                        {displayRules?.includes("Industry") &&
                            generateTagList("Industry").length > 0 && (
                                <div className={`${lightMode} filters-industry`}>
                                    {displayRules?.includes("Element") && (
                                        <div className={`${lightMode} filters-separator`}></div>
                                    )}
                                    <div
                                        className={`${lightMode} filters-industry-header`}
                                        style={
                                            isDropdownOpen.industry
                                                ? {
                                                    backgroundColor:
                                                        lightMode === "light" ? "#f9fafb" : "#303030",
                                                }
                                                : {}
                                        }
                                        onClick={() =>
                                            setIsDropDownOpen((prev) => {
                                                return {...prev, industry: !prev.industry};
                                            })
                                        }>
                                        <img
                                            src={
                                                lightMode === "light"
                                                    ? FiltersIndustry
                                                    : FiltersIndustryWhite
                                            }
                                            style={{marginRight: "17px", width: "17px"}}
                                            alt={"FiltersIndustry"}
                                        />{" "}
                                        Industry
                                        <p className={`${lightMode} filters-numbers`}>
                                            {countScreens("Industry")}
                                        </p>
                                    </div>
                                    {isDropdownOpen.industry && (
                                        <>
                                            <FiltersDropdowns
                                                modes={false}
                                                searchValue={searchValue}
                                                setSelectedTagsCb={(tag: string) =>
                                                    setSelectedTagsCb(tag)
                                                }
                                                selectedTags={selectedTags}
                                                tagList={generateTagList("Industry")}
                                                tagType="Industry"
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        {displayRules?.includes("Company") &&
                            generateTagList("Company").length > 0 && (
                                <div className={`${lightMode} filters-industry`}>
                                    <div className={`${lightMode} filters-separator`}></div>
                                    <div
                                        className={`${lightMode} filters-industry-header`}
                                        style={
                                            isDropdownOpen.companies
                                                ? {
                                                    backgroundColor:
                                                        lightMode === "light" ? "#f9fafb" : "#303030",
                                                }
                                                : {}
                                        }
                                        onClick={() =>
                                            setIsDropDownOpen((prev) => {
                                                return {...prev, companies: !prev.companies};
                                            })
                                        }>
                                        <img
                                            src={
                                                lightMode === "light"
                                                    ? FiltersCompanies
                                                    : FiltersCompaniesWhite
                                            }
                                            style={{marginRight: "17px", width: "17px"}}
                                            alt={"FiltersCompanies"}
                                        />{" "}
                                        Companies
                                        <p className={`${lightMode} filters-numbers`}>
                                            {countScreens("Company")}
                                        </p>
                                    </div>
                                    {!isDropdownOpen.companies && (
                                        <div className={`${lightMode} filters-separator`}></div>
                                    )}
                                    {isDropdownOpen.companies && (
                                        <FiltersDropdowns
                                            modes={false}
                                            searchValue={searchValue}
                                            setSelectedTagsCb={(tag: string) =>
                                                setSelectedTagsCb(tag)
                                            }
                                            selectedTags={selectedTags}
                                            tagList={generateTagList("Company")}
                                            tagType="Company"
                                        />
                                    )}
                                </div>
                            )}
                        {displayRules?.includes("Modes") && (
                            <div className={`${lightMode} filters-industry`}>
                                {displayRules.length === 2 &&
                                    displayRules.includes("Element") && (
                                        <div className={`${lightMode} filters-separator`}></div>
                                    )}
                                <div
                                    className={`${lightMode} filters-industry-header`}
                                    style={
                                        isDropdownOpen.modes
                                            ? {
                                                backgroundColor:
                                                    lightMode === "light" ? "#f9fafb" : "#303030",
                                            }
                                            : {}
                                    }
                                    onClick={() =>
                                        setIsDropDownOpen((prev) => {
                                            return {...prev, modes: !prev.modes};
                                        })
                                    }>
                                    <img
                                        src={lightMode === "light" ? ModesImg : ModesImgDark}
                                        style={{marginRight: "17px", width: "17px"}}
                                        alt={"ModesImg"}
                                    />{" "}
                                    Modes
                                </div>
                                {!isDropdownOpen.modes && (
                                    <div className={`${lightMode} filters-separator`}></div>
                                )}
                                {isDropdownOpen.modes && (
                                    <FiltersDropdownsModes
                                        modes={true}
                                        searchValue={searchValue}
                                        setSelectedTagsCb={(tag: string) => setSelectedTagsCb(tag)}
                                        selectedTags={selectedTags}
                                        tagList={[]}
                                        tagType="Company"
                                        activeFilters={activeFilters}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </StyledFiltersContainer>
            )}
        </>
    );
};

export default Filters;
