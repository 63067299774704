import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLightMode } from "../../store/slice/navigationSlice";
import Sun from "../../icons/sun.svg";
import Moon from "../../icons/moon.svg";
import SunDark from "../../icons/sunDark.svg";
import MoonDark from "../../icons/moonDark.svg";

const LightingModeSwitcher: React.FC = () => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [mode, setMode] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storageTheme = localStorage.getItem("lightingMode");
    setMode(lightMode || storageTheme || "light");
  });

  const toggleMode = (theme: string) => {
    const newMode = mode !== theme ? theme : mode;
    setMode(newMode);
    localStorage.setItem("lightingMode", newMode);
    dispatch(setLightMode(newMode));
  };

  return (
    <div className={`${lightMode} lighting-switch-container-mobile`}>
      <span
        className={`${lightMode} lighting-switch-mobile ${
          mode === "light" && "active"
        }`}
        onClick={() => toggleMode("light")}
      >
        <img src={lightMode === "dark" ? Sun : SunDark} alt={"Light"} />
        Light
      </span>
      <span
        className={`${lightMode} lighting-switch-mobile ${
          mode === "dark" && "active"
        }`}
        onClick={() => toggleMode("dark")}
      >
        <img src={lightMode === "dark" ? Moon : MoonDark} alt={"Dark"} />
        Dark
      </span>
    </div>
  );
};

export default LightingModeSwitcher;
