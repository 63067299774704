import React, {useCallback, useEffect, useState} from "react";
import "./SignUp.css";
import {Link, useNavigate} from "react-router-dom";
import {FetchSignUp, RegisterRequest,} from "../../utils/backend/SignIn SignUp/FetchSignUp";
import {GetUser} from "../../utils/backend/Profile/GetUser";
import {useAppSelector} from "../../store/hooks";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";
import posthog from "posthog-js";
import {SubmitHandler, useForm} from "react-hook-form";
import {isValidEmail} from "../../utils/helpers";

const minPasswordLength = 8;
const passwordNotLongEnoughErrorMessage = 'Password must contain at least 8 characters';
const passwordsDoNotMatchErrorMessage = 'Passwords do not match';

interface SignSwitcherProps {
    setSwitcher: (switchState: string) => void;
}

interface SignUpData {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
}

function SignUp({setSwitcher}: SignSwitcherProps) {
    const navigate = useNavigate();
    const [isEmailAlreadyInUser, setIsEmailAlreadyInUser] = useState<boolean>(false);

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
        setError,
        getValues,
    } = useForm<SignUpData>();

    const [isPasswordVisible, setIsPasswordVisible] = useState({
        repeat: false,
        pwd: false,
    });

    useEffect(() => {
        const currentUserToken = localStorage.getItem("token");
        if (!currentUserToken) {
            return;
        }
        const handleGetUser = async function () {
            const result = await GetUser();
            if (result.error.length > 0) {
                setError("root", {
                        message: "Something unexpected happened, try to reload page or contact us"
                    }
                );
                console.error(result.error);
                return;
            }

            if (result.user) {
                navigate("/Navigation/Screens");
            }
        };
        handleGetUser().catch(e => {
            setError("root", {
                    message:
                        "Something unexpected happened, try to reload page or contact us."
                }
            );
            console.error(e);
        });
    }, [navigate, setError]);

    const onSubmit: SubmitHandler<SignUpData> = useCallback(async (actualData) => {
        setIsEmailAlreadyInUser(false);
        const regReq: RegisterRequest = {
            Email: actualData.email,
            Password: actualData.email,
            fullName: actualData.name,
        };

        FetchSignUp(regReq)
            .then((response) => response)
            .then((data) => {
                if (data.userId) {
                    setIsEmailAlreadyInUser(false);
                    localStorage.setItem("pwd", actualData.password);
                    posthog.capture("userRegistered", {
                        title: actualData.email,
                    });
                    navigate(`/verification?email=${encodeURIComponent(actualData.email)}`);
                } else {
                    setIsEmailAlreadyInUser(true);
                }
            })
            .catch((err) => {
                if ((err = "409")) {
                    setIsEmailAlreadyInUser(true);
                } else {
                    console.error(err);
                }
            });
    }, [navigate]);

    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    useEffect(() => {
        function handleSignUp(ev: KeyboardEvent) {
            if (ev.key === "Enter") {
                handleSubmit(onSubmit)();
            }
        }

        window.addEventListener("keypress", handleSignUp);

        return () => {
            window.removeEventListener("keypress", handleSignUp);
        };
    }, [handleSubmit, onSubmit]);

    return (
        <div className={`${lightMode} sign-page`}>
            <form className={`${lightMode} sign-container`} onSubmit={handleSubmit(onSubmit)}>
                <div className={`${lightMode} form`}>
                    <div style={{marginBottom: "20px"}}>
                        <label htmlFor="email" className={`${lightMode} label`}>
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            className={`${lightMode} email sign-in-input`}
                            placeholder="Enter your name"
                            style={errors.name ? {} : {marginBottom: "20px"}}
                            {...register("name", {required: true})}
                        />
                        {errors.name && (
                            <div
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "10px",
                                    fontWeight: 500,
                                }}
                            >
                                {errors.name.message && errors.name.message?.length > 0
                                    ? errors.name.message
                                    : errors.name && errors.name.type === "required"
                                        ? "Required"
                                        : errors.name && errors.name.type === "validate"
                                            ? "Not valid name"
                                            : null}
                            </div>
                        )}
                        <label htmlFor="email" className={`${lightMode} label`}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            className={`${lightMode} email sign-in-input`}
                            placeholder="Enter your email"
                            {...register("email", {required: true, validate: isValidEmail})}
                        />
                        {errors.email && (
                            <div
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "10px",
                                    fontWeight: 500,
                                }}
                            >
                                {errors.email.message && errors.email.message?.length > 0
                                    ? errors.email.message
                                    : errors.email && errors.email.type === "required"
                                        ? "Required"
                                        : errors.email && errors.email.type === "validate"
                                            ? "Not valid email"
                                            : null}
                            </div>
                        )}

                        {isEmailAlreadyInUser && (
                            <h2 className={`${lightMode} error`}>
                                This email is already in use
                            </h2>
                        )}
                    </div>
                    <label htmlFor="password" className={`${lightMode} label pass-label`}>
                        Password
                    </label>
                    <div style={{marginBottom: "20px"}}>
                        <input
                            type={isPasswordVisible.pwd ? "text" : "password"}
                            id="password"
                            className={`${lightMode} password sign-in-input`}
                            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                            {...register("password", {
                                required: true, validate: (value) => {
                                    return value.length >= minPasswordLength
                                }
                            })}
                        />
                        <img
                            src={isPasswordVisible.pwd ? HideIcon : ShowIcon}
                            onClick={() =>
                                setIsPasswordVisible({
                                    ...isPasswordVisible,
                                    pwd: !isPasswordVisible.pwd,
                                })
                            }
                            alt={isPasswordVisible.pwd ? "HideIcon" : "ShowIcon"}
                            className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
                        />
                        {errors.password && (
                            <div
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "10px",
                                    fontWeight: 500,
                                }}
                            >
                                {errors.password.message && errors.password.message?.length > 0
                                    ? errors.password.message
                                    : errors.password && errors.password.type === "required"
                                        ? "Required"
                                        : errors.password && errors.password.type === "validate"
                                            ? passwordNotLongEnoughErrorMessage
                                            : null}
                            </div>
                        )}
                    </div>

                    <label
                        htmlFor="confirm-password"
                        className={`${lightMode} label pass-label`}
                    >
                        Password confirmation
                    </label>
                    <div>
                        <input
                            type={isPasswordVisible.repeat ? "text" : "password"}
                            id="confirm-password"
                            className={`${lightMode} password sign-in-input`}
                            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                            {...register("confirmPassword", {
                                required: true, validate: (value) => {
                                    return value === getValues().password
                                }
                            })}
                        />
                        <img
                            src={isPasswordVisible.repeat ? HideIcon : ShowIcon}
                            onClick={() =>
                                setIsPasswordVisible({
                                    ...isPasswordVisible,
                                    repeat: !isPasswordVisible.repeat,
                                })
                            }
                            alt={isPasswordVisible.repeat ? "HideIcon" : "ShowIcon"}
                            className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
                        />
                        {errors.confirmPassword && (
                            <div
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                    marginBottom: "10px",
                                    fontWeight: 500,
                                }}
                            >
                                {errors.confirmPassword.message && errors.confirmPassword.message?.length > 0
                                    ? errors.confirmPassword.message
                                    : errors.confirmPassword && errors.confirmPassword.type === "required"
                                        ? "Required"
                                        : errors.confirmPassword && errors.confirmPassword.type === "validate"
                                            ? passwordsDoNotMatchErrorMessage
                                            : null}
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${lightMode} sign-up-buttons`}>
                    <button
                        className={`${lightMode} sign-btn up`}
                        disabled={isSubmitting}
                        type={"submit"}
                    >
                        Sign up
                    </button>
                    {/* <button className={`${lightMode} sign-btn google`}>
						<img src={GoogleIcon} className={`${lightMode} google-icon`}></img>
						Sign up with Google
					</button> */}
                </div>

                <div className={`${lightMode} already-acc`}>
                    <h2>Already have an account?</h2>
                    <Link
                        className={`${lightMode} already-acc-link`}
                        to="/SignIn"
                        onClick={() => setSwitcher("log-in")}
                    >
                        Sign in
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default SignUp;
