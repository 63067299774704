import {useEffect, useState} from "react";
import defaultImage from "../../../icons/image-loader.png";
import {Company as CompanyType} from "../../../utils/types/company";

import "./Company.css";
import {StyledSourceImageBackground} from "../../common/SourcePage/SourcePage.styles";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {resetActiveFilters} from "../../../store/slice/filtersSlice";
import LockImg from "../../../icons/lock.svg";
import LockImgDark from "../../../icons/lock-dark.svg";
import UpgradePlanModal from "../../UpgradePlanModal/UpgradePlanModal";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";

interface CompanyProps {
    company: CompanyType;
}

const Company = ({company}: CompanyProps) => {
    const areFiltersOpen = useAppSelector(
        (state) => state.filters.isFilterOpened
    );
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const [innerWidth, setInnerWidth] = useState<number>(
        document.documentElement.clientWidth
    );
    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(document.documentElement.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleLink = () => {
        dispatch(resetActiveFilters());
        navigate(
            window.location.pathname.includes("/Companies")
                ? `/Navigation/Companies/CompanyInfo?company-id=${company.id}`
                : `/Navigation/Design/Element?design-el-id=${company.id}`
        );
    };

    const locked = company.contentList.find((screen) =>
        screen.name?.includes("Locked Screen")
    );

    const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
        useState<boolean>(false);
    const {isMobile} = useIsMobile();

    return (
        <a
            className={`${lightMode} source-page-display-grid-item`}
            onClick={() => {
                if (!locked) handleLink();
            }}
            style={locked ? {cursor: "default"} : {}}
        >
            <div className={`${lightMode} company-source-card`}>
                <UpgradePlanModal
                    onClose={() => setIsUpgradePlanModalOpened(false)}
                    isOpen={isUpgradePlanModalOpened}
                    type="limit"
                />
                <div className={`${lightMode} company-screens company item`}>
                    <StyledSourceImageBackground
                        innerWidth={innerWidth}
                        isMobile={isMobile}
                        areFiltersOpen={areFiltersOpen}
                        style={
                            !locked
                                ? {
                                    backgroundImage: `url(${
                                        company.contentList.length > 0 &&
                                        company.contentList[0].presignedURLResized
                                            ? company.contentList[0].presignedURLResized
                                            : defaultImage
                                    })`,
                                }
                                : lightMode === "light"
                                    ? {
                                        backgroundColor: "#EAECF0",
                                        cursor: "default",
                                    }
                                    : {
                                        backgroundColor: "#1E1E1E",
                                        cursor: "default",
                                    }
                        }
                    />
                    <StyledSourceImageBackground
                        innerWidth={innerWidth}
                        isMobile={isMobile}
                        areFiltersOpen={areFiltersOpen}
                        style={
                            !locked
                                ? {
                                    backgroundImage: `url(${
                                        company.contentList.length > 1 &&
                                        company.contentList[1].presignedURLResized
                                            ? company.contentList[1].presignedURLResized
                                            : defaultImage
                                    })`,
                                }
                                : lightMode === "light"
                                    ? {
                                        backgroundColor: "#EAECF0",
                                        cursor: "default",
                                    }
                                    : {
                                        backgroundColor: "#1E1E1E",
                                        cursor: "default",
                                    }
                        }
                    />
                    <StyledSourceImageBackground
                        innerWidth={innerWidth}
                        isMobile={isMobile}
                        areFiltersOpen={areFiltersOpen}
                        style={
                            !locked
                                ? {
                                    backgroundImage: `url(${
                                        company.contentList.length > 2 &&
                                        company.contentList[2].presignedURLResized
                                            ? company.contentList[2].presignedURLResized
                                            : defaultImage
                                    })`,
                                }
                                : lightMode === "light"
                                    ? {
                                        backgroundColor: "#EAECF0",
                                        cursor: "default",
                                    }
                                    : {
                                        backgroundColor: "#1E1E1E",
                                        cursor: "default",
                                    }
                        }
                    />
                    <StyledSourceImageBackground
                        innerWidth={innerWidth}
                        isMobile={isMobile}
                        areFiltersOpen={areFiltersOpen}
                        style={
                            !locked
                                ? {
                                    backgroundImage: `url(${
                                        company.contentList.length > 3 &&
                                        company.contentList[3].presignedURLResized
                                            ? company.contentList[3].presignedURLResized
                                            : defaultImage
                                    })`,
                                }
                                : lightMode === "light"
                                    ? {
                                        backgroundColor: "#EAECF0",
                                        cursor: "default",
                                    }
                                    : {
                                        backgroundColor: "#1E1E1E",
                                        cursor: "default",
                                    }
                        }
                    />
                    {locked && (
                        <button
                            className={`${lightMode} purchase-plan-locked company-element-locked`}
                            onClick={() => setIsUpgradePlanModalOpened(true)}
                            style={{
                                top: "50%",
                            }}
                        >
                            <img
                                src={lightMode === "light" ? LockImg : LockImgDark}
                                alt={"locked"}
                            />
                            Upgrade to see this
                            {window.location.pathname.includes("/Companies")
                                ? " company"
                                : " element"}
                        </button>
                    )}
                </div>
                <div className={`${lightMode} company-info`}>
                    {!window.location.pathname.includes("/Design") && (
                        <img src={company.image} alt=""/>
                    )}
                    <div className={`${lightMode} company-details`}>
                        <h2>{company.name}</h2>
                        <h3>{company.description}</h3>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default Company;
