import {useEffect, useState} from "react";

const mobileMaxWidth = 875;

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.screen.width < mobileMaxWidth);

    useEffect(() => {
        const onScreenWidthChange = () => {
            setIsMobile(window.screen.width < mobileMaxWidth)
        }
        window.addEventListener("resize", onScreenWidthChange);
        return () => window.removeEventListener("resize", onScreenWidthChange);
    }, []);
    return {
        isMobile,
    }
}