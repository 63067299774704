import React, {useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../../store/hooks";

import "./UpgradePlanStyles.css";
import UpgradePlanSwitcher from "./UpgradePlanSwitcher";
import closeIcon from "../../icons/closeWhite.svg";
import closeIconDark from "../../icons/close.svg";
import PersonalPlan from "./PersonalPlan";
import TeamPlan from "./TeamPlan";
import FreePlan from "./FreePlan";
import {useLocation, useSearchParams} from "react-router-dom";
import {PackageType} from "../SetupProfile/InitialBuyPlanPage";
import posthog from "posthog-js";
import {useIsMobile} from "../../utils/hooks/useIsMobile";

const TextEnum = {
    limit: "screens limit",
    boardLimit: "boards limit",
    workspaceUserLimit: "workspace users limit",
    boardUsersLimit: "board users limit",
    collectionsLimit: "collections limit",
};

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    type:
        | "new"
        | "limit"
        | "boardLimit"
        | "workspaceUserLimit"
        | "boardUsersLimit"
        | "collectionsLimit";
    children?: React.ReactNode;
}

const UpgradePlanModal: React.FC<ModalProps> = ({isOpen, onClose, type}) => {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const [selectedPackageType, setSelectedPackageType] = useState<PackageType>(
        PackageType.Personal
    );
    const [searchParams] = useSearchParams();
    const quantity = searchParams.get("quantity");
    const currentPaymentPlan = useAppSelector(
        (state) => state.boards.selectedProject?.paymentPlan
    );

    const isUserOnFreePlan = useMemo(() => {
        return (
            currentPaymentPlan &&
            currentPaymentPlan.plan &&
            !currentPaymentPlan.plan.stripePriceId
        );
    }, [currentPaymentPlan]);

    useEffect(() => {
        if (isUserOnFreePlan === false) {
            setSelectedPackageType(PackageType.Team);
        }
    }, [isUserOnFreePlan]);

    useEffect(() => {
        if (quantity && parseInt(quantity) >= 2) {
            setSelectedPackageType(PackageType.Team);
        }
    }, [quantity]);

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const location = useLocation();

    useEffect(() => {
        const pageTitle = document.title || "Default Title";

        if (isOpen) {
            posthog.capture("paymentsModalOpened", {
                path: location.pathname,
                title: pageTitle,
            });
        }
    }, [isOpen, location]);

    const {isMobile} = useIsMobile();

    if (!isOpen) return null;

    return (
        <div
            className={`${lightMode} modal-overlay upgrade-plan`}
            onClick={handleOverlayClick}
        >
            <div
                className={`${lightMode} modal upgrade-plan${
                    !isUserOnFreePlan ? "-existing" : ""
                }`}
            >
                <div className={`${lightMode} modal-content upgrade-plan`}>
                    <div className={`${lightMode} upgrade-plan-header `}>
                        <p className={`${lightMode} p-modal-header`}>
                            {!isUserOnFreePlan
                                ? "Upgrade your team plan"
                                : type === "new"
                                    ? "Upgrade your plan"
                                    : "Account limit reached"}
                        </p>

                        <div className={`${lightMode} upgrade-plan-header-right`}>
                            {isUserOnFreePlan && (
                                <UpgradePlanSwitcher
                                    setSwitcher={(switchState: PackageType) =>
                                        setSelectedPackageType(switchState)
                                    }
                                    switchState={selectedPackageType}
                                />
                            )}
                            <div
                                className={`${lightMode} filters-img-container upgrade-plan`}
                                onClick={onClose}
                            >
                                <img
                                    alt="close"
                                    src={lightMode === "light" ? closeIconDark : closeIcon}
                                />
                            </div>
                        </div>
                    </div>
                    {type !== "new" && isUserOnFreePlan && (
                        <div
                            className={`${lightMode} account-limit`}
                            style={{
                                maxWidth: isMobile ? "100%" : "60%",
                            }}
                        >
                            You have reached the maximum {TextEnum[type]} within your current
                            plan. If you liked the application and want to continue, you can
                            purchase a lifetime license.
                        </div>
                    )}
                    <div
                        className={`${lightMode} upgrade-plan-content`}
                        style={{
                            flexDirection: isMobile ? "column" : "row",
                            gap: "20px",
                        }}
                    >
                        {isUserOnFreePlan && (
                            <FreePlan activePlanType={selectedPackageType}/>
                        )}
                        {selectedPackageType === PackageType.Personal &&
                        isUserOnFreePlan ? (
                            <PersonalPlan/>
                        ) : (
                            <TeamPlan isUserOnFreePlan={isUserOnFreePlan}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradePlanModal;
