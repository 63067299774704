import {Fetch, FetchInput, Method} from "../../Fetch";
import {Workspace} from "../../types/workspace";

export interface RemoveInvitationResponse {
    response: Workspace[];
    error: string;
}

export async function RemoveInvitation(
    inviteID: string,
    boardID: string,
    signal?: AbortSignal
): Promise<RemoveInvitationResponse> {
    const RemoveInvitation = `/me/board/decline_invite`;

    const bodyJSON = JSON.stringify({
        inviteID: inviteID,
        boardID: boardID,
    });
    try {
        const input: FetchInput = {
            method: Method.Post,
            url: RemoveInvitation,
            signal: signal,
            body: bodyJSON,
        };
        const result = await Fetch(input);

        if (result.isOk) {
            const response = await result;
            if (response) {
                return {
                    response: response.result.result,
                    error: "",
                };
            }

            return {
                response: [],
                error: "Server side error",
            };
        } else {
            return {
                response: [],
                error: result.error?.message ?? "",
            };
        }
    } catch (err) {
        console.error(err);
        return {
            response: [],
            error: "error",
        };
    }
}
