import React, {useEffect} from "react";
import "./CreateCollectionModal.css";
import closeIcon from "../../icons/close.svg";
import closeIconDark from "../../icons/closeWhite.svg";
import {CreateCollection, CreateCollectionRequest,} from "../../utils/backend/Collection/CreateCollection";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {setShouldBlockKeyboardNavigation} from "../../store/slice/navigationSlice";
import {SubmitHandler, useForm} from "react-hook-form";

interface NoCollectionModalProps {
    close: Function;
    created: Function;
}

const CreateCollectionModal = ({close, created}: NoCollectionModalProps) => {
    const isOpen = useAppSelector(
        (state) => state.navigation.shouldBlockKeyboardNavigation
    );

    const {
        handleSubmit,
        register,
        formState: {errors, isLoading},
        setError,
    } = useForm<{ name: string }>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setShouldBlockKeyboardNavigation(true));
    }, [dispatch, isOpen]);

    const onSubmit: SubmitHandler<{ name: string }> = async (data) => {
        const controller = new AbortController();
        const signal = controller.signal;
        const request: CreateCollectionRequest = {
            name: data.name,
            signal: signal,
        };

        try {
            const result = await CreateCollection(request);
            if (result.error.length > 0 || !result.collectionId) {
                setError("root", {
                        message: "Something unexpected happened while creating collection, try to reload page or contact us."
                    }
                );
                console.error(result.error);
                return;
            } else {
                created();
            }
            return result.collectionId;
        } catch (e) {
            setError("root", {
                    message: "Something unexpected happened while creating collection, try to reload page or contact us."
                }
            );
        }
    }

    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${lightMode} no-collections-modal-container`}>
                <div
                    className={`${lightMode} no-collection-modal-dimmer`}
                    onClick={() => close()}/>
                <div className={`${lightMode} no-collections-modal`}>
                    <div className={`${lightMode} no-collections-modal-header`}>
                        New collection{" "}
                        <img
                            className={`${lightMode} no-collections-modal-close-icon`}
                            src={lightMode === "light" ? closeIcon : closeIconDark}
                            onClick={() => close()}
                            alt={"CloseIcon"}
                        />
                    </div>
                    <div className={`${lightMode} no-collections-modal-input-container`}>
                        <label className={`${lightMode} no-collection-modal-label`}>
                            Name{" "}
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="Enter your collection name"
                            className={`${lightMode} no-collections-modal-input`}
                            {...register("name", {required: true})}
                        />
                        {errors.name && (
                            <div style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                fontWeight: 500,
                                marginBottom: '20px'
                            }}>
                                {errors.name.message && errors.name.message.length > 0
                                    ? errors.name.message
                                    : errors.name && errors.name.type === "required"
                                        ? "Required"
                                        : null}
                            </div>
                        )}
                    </div>
                    <div className={`${lightMode} no-collections-modal-button-container`}>
                        <button
                            className={`${lightMode} no-collections-modal-button-white`}
                            onClick={() => close()}>
                            Cancel
                        </button>
                        <button
                            className={`${lightMode} no-collections-modal-button-black`}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading..." : "Create"}
                        </button>
                        <h2 className={`${lightMode} log-error`}>
                            {errors.root && errors.root.message ? errors.root.message : null}
                        </h2>
                    </div>
                </div>
            </div>
        </ form>
    );
};

export default CreateCollectionModal;
