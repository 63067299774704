import "../../companies/CompanyInfo/CompanyInfo.css";
import {useEffect, useRef, useState} from "react";
import Screen from "../Screen/Screen";
import {Tag} from "../../../utils/types/tag";
import {Screen as ScreenType} from "../../../utils/types/screen";
import {queryData} from "../../../utils/types/queryData";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {fetchScreens} from "./initialize";
import {arraysHaveSameElements, usePrevious,} from "../../../utils/usePrevious";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Loader from "../../Loader/Loader";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import {getAllCollections} from "../../companies/CompanyInfo/initializeData";
import {Collection} from "../../../utils/types/collection";
import Filters from "../../FiltersComponent/Filters";
import {PreviewScreen} from "../../common/previewScreen/PreviewScreen";
import {setIsPreviewScreenOpen} from "../../../store/slice/navigationSlice";
import {StyledGridDiv} from "../../common/ProductPage/ProductPage.styles";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";

const Screens = () => {
    const allTagTypesWithTags = useAppSelector(
        (state) => state.tags.allTagTypesWithTags
    );

    const [screens, setScreens] = useState<ScreenType[]>([]);
    const [companyTags, setCompanyTags] = useState<Tag[]>([]);
    const [queryState, setQueryState] = useState<queryData>({
        limit: 36,
        page: 0,
    });
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [sliderOpened, setSliderOpened] = useState(false);
    const [currentScreenIndex, setCurrentScreenIndex] = useState<
        number | undefined
    >();
    const activeFilters = useAppSelector((state) => state.filters.activeFilters);
    const shouldDisplayFilters: any = useAppSelector(
        (state) => state.filters.isFilterOpened
    );
    const [seed, setSeed] = useState(Math.random());

    const previousActiveFilters = usePrevious<string[]>(activeFilters);
    const previousQueryState = usePrevious<queryData>(queryState);
    const [isLoadingMore, setIsLoadingMore] = useState(true);
    const [areCollectionsFetched, setAreCollectionsFetched] = useState(false);
    const [collectionList, setCollectionList] = useState<Collection[]>([]);
    const dispatch = useAppDispatch();
    const [innerWidth, setInnerWidth] = useState<number>(
        document.body.clientWidth
    );
    const isLocked = useAppSelector((state) => state.navigation.limitExceeded);

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(document.body.clientWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isLoadingMore]);
    const loader = useRef(null);

    useEffect(() => {
        const loaderCopy = {
            ...loader
        };
        if (loaderCopy.current && screens.length > 0) {
            const options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0,
            };

            const handleObserver = (entities: IntersectionObserverEntry[]) => {
                const handleLoadMore = async () => {
                    if (isLocked) return;
                    setIsLoadingMore(true);

                    if (isLastPage) return;
                    setQueryState((prev) => {
                        return {
                            limit: prev.limit,
                            page: prev.page + 1,
                        };
                    });
                };

                const target = entities[0];
                if (target.isIntersecting && screens.length > 0) {
                    handleLoadMore().catch(err => {
                        console.error(err);
                        setError("Unable to get data. Try again or contact us");
                    }).finally(() => {
                        setIsLoadingMore(false);
                    });
                }
            };

            const observer = new IntersectionObserver(handleObserver, options);
            observer.observe(loaderCopy.current);

            return () => {
                if (loaderCopy && loaderCopy.current) {
                    observer.unobserve(loaderCopy.current);
                }
            };
        }
    }, [isLastPage, isLocked, screens]);

    useEffect(() => {
        if (!areCollectionsFetched) {
            getAllCollections({
                setError: setError,
                setCollections: setCollectionList,
            }).catch((e) => {
                console.error(e);
                setError("Unable to get data. Try again or contact us");
            }).finally(() => {
                setAreCollectionsFetched(true);
            });
        }
    }, [areCollectionsFetched]);

    useEffect(() => {
        if (
            !!previousActiveFilters &&
            arraysHaveSameElements(previousActiveFilters, activeFilters)
        )
            return;

        if (activeFilters.length !== 0) {
            setQueryState({
                page: 0,
                limit: 36,
            });
            setSeed(Math.random());
        }
        setScreens([]);
    }, [activeFilters, previousActiveFilters]);

    useEffect(() => {
        if (
            !!previousQueryState &&
            queryState.page === previousQueryState.page &&
            queryState.limit === previousQueryState.limit &&
            !!previousActiveFilters &&
            arraysHaveSameElements(activeFilters, previousActiveFilters)
        ) {
            return;
        }

        setIsLoadingMore(true);
        setError(undefined);
        if (
            screens.length === 0 &&
            activeFilters.length > 0 &&
            previousActiveFilters === undefined
        ) {
            return;
        }

        const fetchScreensByActiveFilter = async () => {
            const controller = new AbortController();
            const signal = controller.signal;

            const filterString: string[] = [];
            const industryTagList = allTagTypesWithTags.find(
                (tag) => tag.tagType.name === "Industry"
            )?.tagList;
            const companiesTagList = allTagTypesWithTags.find(
                (tag) => tag.tagType.name === "Company"
            )?.tagList;

            activeFilters.forEach((filter) => {
                if (industryTagList?.find((tag) => tag.id === filter)) {
                    companiesTagList?.forEach((company) => {
                        if (company.tagList.find((t) => t.id === filter)) {
                            filterString.push(company.id);
                        }
                    });
                } else {
                    filterString.push(filter);
                }
            });

            await fetchScreens({
                queryState,
                setScreens,
                activeFilters: filterString,
                signal,
                setError,
                setIsLastPage,
                seedValue: seed,
            });
        };

        if (
            (queryState.page + 1) * 36 >=
            screens.filter((screen) => screen.id !== "screen-12345").length ||
            (screens.length === 0 && activeFilters.length === 0)
        ) {
            fetchScreensByActiveFilter()
                .catch((e) => {
                    console.error(e);
                    setError("Can't get data from server");
                })
                .finally(() => {
                    setIsLoadingMore(false);
                });
        }
    }, [activeFilters, queryState, previousActiveFilters, previousQueryState, screens, allTagTypesWithTags, seed]);

    useEffect(() => {
        setCompanyTags(
            allTagTypesWithTags.find((tt) => {
                return tt.tagType.name === "Company";
            })?.tagList ?? []
        );
    }, [allTagTypesWithTags]);

    const collectionChangedFunc = (
        screenId: string,
        collectionId: string,
        type: string
    ) => {
        if (!screens) return;
        if (type === "add") {
            const update = screens;
            update.forEach((screen) => {
                if (screen.id === screenId) {
                    screen.collectionIdList.push(collectionId);
                }
            });
            setScreens(update);
        } else if (type === "remove") {
            const update = screens;
            update.forEach((screen) => {
                if (screen.id === screenId) {
                    screen.collectionIdList = screen.collectionIdList.filter(
                        (id: string) => {
                            return id !== collectionId;
                        }
                    );
                }
            });
            setScreens(update);
        }
    };

    const displayScreens = screens.map((screen: ScreenType, index: number) => {
        let companyName = "";
        let companyIcon = "";

        companyTags.forEach((company: Tag) => {
            const foundCompany = screen.tagList.find((t) => {
                return (
                    t.id === company.id && company.noOfScreens && company.noOfScreens > 0
                );
            });
            if (foundCompany) {
                companyIcon = company.imageS3URL || "";
                companyName = company.name;
                return;
            }
        });
        if (index < (queryState.page + 1) * 36) {
            return (
                <Screen
                    key={screen.id}
                    screen={screen}
                    companyName={companyName}
                    companyIcon={companyIcon}
                    collectionChangedFunc={collectionChangedFunc}
                    onClicked={() => {
                        if (screen.locked) return;
                        setSliderOpened(true);
                        setCurrentScreenIndex(index);
                    }}
                />
            );
        }
        return null;
    });

    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const {isMobile} = useIsMobile();

    return (
        <div className={`${lightMode}`} style={{
            width: "100%"
        }}>
            {screens.length > 0 && (
                <PreviewScreen
                    screenList={screens}
                    opened={sliderOpened}
                    collectionList={collectionList}
                    setCollectionList={setCollectionList}
                    setCurrentScreenIndex={setCurrentScreenIndex}
                    handleClose={() => {
                        setSliderOpened(false);
                        dispatch(setIsPreviewScreenOpen(false));
                    }}
                    currentScreenIndex={currentScreenIndex}
                    company={companyTags.find((company) =>
                        screens[currentScreenIndex ?? 0].tagList.find(
                            (tag: Tag) => tag.id === company.id
                        )
                    )}
                />
            )}

            <div
                className={`${lightMode} grid-container`}
                id="grid-container"
                style={{display: "flex"}}
            >
                <StyledGridDiv
                    isLoading={isLoadingMore}
                    isMobile={isMobile}
                    areFiltersOpen={shouldDisplayFilters}
                    innerWidth={innerWidth}
                >
                    {screens.length === 0 && isLoadingMore && <Loader/>}
                    {error && error.length > 0 && <ErrorPage/>}
                    {screens.length === 0 && !isLoadingMore && !error && <NoResultsFoundPage/>}
                    {screens.length > 0 && displayScreens}
                </StyledGridDiv>
                <Filters displaysFrom="screens"/>

            </div>

            <div ref={loader} style={{height: "10px"}}></div>
        </div>
    );
};
export default Screens;
