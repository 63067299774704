import {useAppSelector} from "../../store/hooks";
import "./Loader.css";

const Loader = () => {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    return (
        <center className={`${lightMode} loader-center-comp`}>
            <div className={`${lightMode} spinner-loader`}>
                <iframe
                    src="https://giphy.com/embed/fphXG8dDcRHVavls9o"
                    width="22px"
                    height="22px"
                    frameBorder="0"
                    className={`${lightMode} giphy-embed`}
                    allowFullScreen
                    title={"loader"}
                />
            </div>
        </center>
    );
};

export default Loader;
