import icon from "../../../icons/CollectionImg.svg";
import "./CollectionInfo.css";
import {TagByTagType, TagTypeType,} from "../../../utils/backend/Company/TagByTagType";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GetCollectionById} from "../../../utils/backend/Collection/GetCollectionById";
import {validate as isValidUUID} from "uuid";
import {Collection} from "../../../utils/types/collection";
import {PreviewScreen} from "../../common/previewScreen/PreviewScreen";
import {getAllCollections} from "./initializeData";
import {Tag} from "../../../utils/types/tag";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {setIsPreviewScreenOpen} from "../../../store/slice/navigationSlice";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Loader from "../../Loader/Loader";
import CompanyItem from "../../companies/CompanyItem/CompanyItem";
import {StyledGridDiv} from "../../common/ProductPage/ProductPage.styles";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import threeDots from "../../../icons/wierdIconBlack.svg";
import threeDotsWhite from "../../../icons/wierdIconWhite.svg";
import EditImg from "../../../icons/edit-3.svg";
import EditImgDark from "../../../icons/edit-3-dark.svg";
import Trash from "../../../icons/trash-2.svg";
import {DeleteCollection, DeleteCollectionRequest,} from "../../../utils/backend/Collection/DeleteCollection";
import {asyncNotification} from "../../../store/slice/notificationSlice";
import EditCollectionModal from "./EditCollectionModal";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";

const CollectionInfo = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState("");
    const [collection, setCollection] = useState<Collection>();
    const [companyTags, setCompanyTags] = useState<Tag[]>([]);
    const [isFetched, setIsFetched] = useState(false);

    const [collectionList, setCollectionList] = useState<Collection[]>([]);
    const [isPlanLimitExceeded, setIsPlanLimitExceeded] =
        useState<boolean>(false);

    useEffect(() => {
        if (isFetched) return;
        const collectionId = searchParams.get("collection-id");
        if (!collectionId || !isValidUUID(collectionId)) {
            setError(
                "Looks like you entered or copied wrong URL, please check or contact us"
            );
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;

        async function init(collectionId: string) {
            try {
                const res = await GetCollectionById({
                    id: collectionId,
                    signal: signal,
                });

                if (res.error.length > 0) {
                    setError(
                        "Something unexpected happened, try to reload page or contact us."
                    );
                    return;
                }

                if (!res.collection) {
                    setError("Collection not found.");
                    return;
                }

                setIsPlanLimitExceeded(res.planLimitExceeded);
                setCollection(res.collection);
                setIsFetched(true);
                const data = await TagByTagType(TagTypeType.TagTypeCompany, signal);
                setCompanyTags(data.tags);
            } catch (e) {
                setError(
                    "Something unexpected happened, try to reload page or contact us."
                );
            }
        }

        init(collectionId).catch(err => {
            console.error(err);
            setError("Something went wrong")
        });

        return () => {
            controller.abort();
        };
    }, [isFetched, searchParams]);

    const removeScreen = (screenId: string) => {
        if (collection?.contentList)
            setCollection({
                ...collection,
                contentList: collection?.contentList.filter(
                    (screen) => screen.id !== screenId
                ),
            });
    };

    if (error.length > 0) {
        return <ErrorPage/>;
    }

    if (!collection) {
        return <Loader/>;
    }

    return (
        <DisplayCollectionInfo
            removeScreen={(screenId: string) => removeScreen(screenId)}
            collection={collection}
            companyTags={companyTags ?? []}
            setCollection={setCollection}
            collectionList={collectionList}
            setCollectionList={setCollectionList}
            setError={setError}
            isPlanLimitExceeded={isPlanLimitExceeded}
        />
    );
};

interface DisplayCollectionInfoProps {
    collection: Collection;
    companyTags: Tag[];
    collectionList: Collection[];
    setCollectionList: React.Dispatch<React.SetStateAction<Collection[]>>;
    setError: (err: string) => void;
    removeScreen: (id: string) => void;
    setCollection: React.Dispatch<React.SetStateAction<Collection | undefined>>;
    isPlanLimitExceeded: boolean;
}

const DisplayCollectionInfo = (
    {
        collection,
        companyTags,
        setCollection,
        setCollectionList,
        collectionList,
        setError,
        removeScreen,
        isPlanLimitExceeded,
    }: DisplayCollectionInfoProps) => {
    const navigate = useNavigate();
    const [sliderOpened, setSliderOpened] = useState(false);
    const [currentScreen, setCurrentScreen] = useState<number | undefined>();

    const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);
    const [isEditCollectionModalOpen, setIsEditCollectionModalOpen] =
        useState(false);

    const collectionChangedFunc = (
        screenId: string,
        collectionId: string,
        type: string
    ) => {
        if (type === "add") {
            const update = collection.contentList ?? [];
            update.forEach((screen) => {
                if (screen.id === screenId) {
                    screen.collectionIdList.push(collectionId);
                }
            });
            setCollection({
                ...collection,
                contentList: update,
            });
        } else if (type === "remove") {
            const update = collection.contentList ?? [];

            update.forEach((screen) => {
                if (screen.id === screenId) {
                    screen.collectionIdList = screen.collectionIdList.filter(
                        (id: string) => {
                            return id !== collectionId;
                        }
                    );
                }
            });
            setCollection({
                ...collection,
                contentList: update,
            });
        }
    };

    useEffect(() => {
        getAllCollections({
            setError: setError,
            setCollections: setCollectionList,
        }).catch((err) => {
            setError(
                "Something unexpected happened, try to reload page or contact us."
            );
            console.error(err);
        });
    }, [setCollectionList, setError]);

    const displayScreens = () => {
        return collection.contentList?.map((screen, index) => {
            return (
                <CompanyItem
                    name={screen.name}
                    id={screen.id}
                    image={screen.presignedURLResized ?? screen.presignedURL}
                    key={screen.id}
                    companyName={""}
                    companyIcon={""}
                    collectionId={collection.id}
                    removeScreen={(screenId: string) => removeScreen(screenId)}
                    collectionList={screen.collectionIdList}
                    collectionChangedFunc={collectionChangedFunc}
                    handleClick={() => {
                        setSliderOpened(true);
                        setCurrentScreen(index);
                    }}
                />
            );
        });
    };

    const dispatch = useAppDispatch();
    const [innerWidth, setInnerWidth] = useState<number>(
        document.body.clientWidth
    );
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const handleResize = () => {
        setInnerWidth(document.body.clientWidth);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const deleteCollection = async (collectionId: string) => {
        try {
            const controller = new AbortController();
            const signal = controller.signal;

            const req: DeleteCollectionRequest = {
                signal: signal,
                collectionId: collectionId,
            };

            if (!collection.id) return;

            const result = await DeleteCollection(req);

            if (!result.error) {
                dispatch(
                    asyncNotification({
                        notification: "Collection removed",
                        isActive: true,
                        isErrNotification: false,
                    })
                );
                navigate("/Navigation/Collections");
            } else {
                asyncNotification({
                    notification: "Failed to remove collection",
                    isActive: true,
                    isErrNotification: true,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const {isMobile} = useIsMobile();

    return (
        <div className={`${lightMode} company-page pagee`}>
            {collection.contentList && collection.contentList.length > 0 && (
                <PreviewScreen
                    opened={sliderOpened}
                    screenList={collection.contentList}
                    handleClose={() => {
                        setSliderOpened(false);
                        dispatch(setIsPreviewScreenOpen(false));
                    }}
                    setCurrentScreenIndex={setCurrentScreen}
                    currentScreenIndex={currentScreen}
                    collectionList={collectionList}
                    setCollectionList={setCollectionList}
                    company={companyTags.find(
                        (company) =>
                            collection.contentList &&
                            collection.contentList[currentScreen ?? 0].tagList.find(
                                (tag: Tag) => tag.id === company.id
                            )
                    )}
                />
            )}
            {isEditCollectionModalOpen && (
                <EditCollectionModal
                    collection={collection}
                    isOpen={isEditCollectionModalOpen}
                    onClose={() => setIsEditCollectionModalOpen(false)}
                    onUpdateSuccessfully={(newCollection: Collection) => {
                        setCollection((prev) => {
                            if (!prev) return;
                            return {
                                ...prev,
                                name: newCollection.name,
                            };
                        });
                        dispatch(
                            asyncNotification({
                                notification: "Collection updated successfully.",
                                isActive: true,
                            })
                        );
                    }}
                />
            )}
            <div className={`${lightMode} tags-info-header`}>
                <div className={`${lightMode} info`} style={{marginBottom: "22px"}}>
                    <img
                        className={`${lightMode} info image`}
                        style={{border: 0}}
                        src={icon}
                        alt=""
                    />
                    <span className={`${lightMode} details`}>
            <h2 style={{marginBottom: "2px"}}>
              {collection && collection.name}
            </h2>
            <h3>
              {collection.contentList ? collection.contentList.length : 0}{" "}
                screens
            </h3>
          </span>

                    <div className={`${lightMode} path`}>
                        <h3
                            className={`${lightMode} link-name first`}
                            onClick={() => {
                                navigate("/Navigation/Collections");
                            }}
                        >
                            Collections{" "}
                        </h3>
                        <h3 className={`${lightMode} first`}> / </h3>
                        <h3 className={`${lightMode} collection-name second`}>
                            {collection.name}
                        </h3>
                        <div
                            className={`${lightMode} head-3`}
                            style={
                                openOptionsDropdown && lightMode === "light"
                                    ? {outline: "#EAECF0 solid 2px", marginLeft: "3px"}
                                    : {marginLeft: "3px"}
                            }
                            onClick={() => {
                                setOpenOptionsDropdown((prev) => !prev);
                            }}
                        >
                            <img
                                src={lightMode === "light" ? threeDots : threeDotsWhite}
                                alt="three-dots-icon"
                            />
                            {openOptionsDropdown && (
                                <div className={`edit-collection-dropdown ${lightMode}`}>
                                    <div
                                        className={`${lightMode} edit-collection-dropdown-div`}
                                        onClick={() => {
                                            setIsEditCollectionModalOpen(true);
                                        }}
                                    >
                                        <img
                                            src={lightMode === "light" ? EditImg : EditImgDark}
                                            alt="edit"
                                        />{" "}
                                        Edit collection
                                    </div>
                                    <div
                                        className={`${lightMode} edit-collection-dropdown-div`}
                                        style={{color: "#EB5757"}}
                                        onClick={() => deleteCollection(collection.id)}
                                    >
                                        <img src={Trash} alt="trash"/>
                                        Remove collection
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${lightMode} grid-container`} id="grid-container">
                {collection.contentList && collection.contentList.length > 0 ? (
                    <StyledGridDiv
                        innerWidth={innerWidth}
                        areFiltersOpen={false}
                        isMobile={isMobile}
                    >
                        {displayScreens()}
                    </StyledGridDiv>
                ) : (
                    <NoResultsFoundPage planLimitExceeded={isPlanLimitExceeded}/>
                )}
            </div>
        </div>
    );
};
export default CollectionInfo;
