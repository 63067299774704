import {setIfLimitIsExceeded} from "../store/slice/navigationSlice";
import {store} from "../store/store";

const BackendURL = process.env.BACKEND_URL || "https://api.uxmaps.co";
//
//http://localhost:5002
export enum Method {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Patch = "PATCH",
    Delete = "DELETE",
}

export interface Error {
    code: number;
    message: string;
}

export interface Response {
    isOk: boolean;
    error?: Error;
    result?: any;
}

export interface FetchInput {
    url: string;
    method: Method;
    body?: string;
    signal?: AbortSignal;
}

export async function Fetch({
                                signal,
                                method,
                                url,
                                body,
                            }: FetchInput): Promise<Response> {
    const userToken = JSON.parse(localStorage.getItem("token") || "{}") ?? "";
    try {
        const result = await fetch(BackendURL + url, {
            method: method.toString(),
            body: body,
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userToken.token}`,
            },
            signal: signal,
        });

        if (!result.ok) {
            if (result.status === 409) {
                const bodyString = await result.text();
                if (bodyString.includes("NewSessionActive")) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("projectId");
                    localStorage.removeItem("boardId");
                    localStorage.removeItem("allTagTypesWithTags");
                    window.location.href = "/SignIn?active=login&loggedOut=true";
                    return {
                        isOk: true,
                    };
                }
            }
            return {
                isOk: false,
                error: {
                    code: result.status,
                    message: result.statusText,
                },
            };
        }
        const res = await result.json();

        if (res.planLimitExceeded) {
            store.dispatch(setIfLimitIsExceeded(true));
        }
        return {
            isOk: true,
            result: res,
        };
    } catch (err: any) {
        if (
            err.toString().includes("Unexpected end of JSON input") ||
            err.toString().includes("unexpected end of data") ||
            err.toString().includes("The string did not match the expected pattern.")
        ) {
            return {
                isOk: true,
            };
        }
        return {
            isOk: false,
            error: {
                code: 500,
                message: err.message,
            },
        };
    }
}
