import styled from "styled-components";

interface StyledGridDivProps {
    areFiltersOpen: boolean;
    innerWidth: number;
    isMobile: boolean;
}

interface StyledSourceImageBackgroundProps {
    areFiltersOpen: boolean;
    innerWidth: number;
    isMobile: boolean;
}

export const StyledGridDivSourcePage = styled.div<StyledGridDivProps>`
	width: ${({areFiltersOpen, isMobile}) =>
    areFiltersOpen && isMobile ? "calc(100% - 308px) " : ""};
    }
	height: fit-content;
	display: grid;
	list-style: none;
	justify-content: start;

	@media only screen and (min-width: 2150px)  {
		grid-template-columns: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `repeat(6,calc(${innerWidth - 342}px / 6))`
        : `repeat(7,calc(${innerWidth - 36}px / 7))`};
		}

	@media only screen and (min-width: 1725px) and (max-width: 2150px) {
		grid-template-columns: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `repeat(5,calc(${innerWidth - 342}px / 5))`
        : `repeat(6,calc(${innerWidth - 36}px / 6))`};
	}
			

	@media only screen and (min-width: 1470px) and (max-width: 1725px) {
		grid-template-columns: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `repeat(4,calc(${innerWidth - 342}px / 4))`
        : `repeat(5,calc(${innerWidth - 36}px / 5))`};
		}

	@media only screen and (min-width: 1298px) and (max-width: 1470px) {
		grid-template-columns: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `repeat(3,calc(${innerWidth - 342}px / 3))`
        : `repeat(4,calc(${innerWidth - 36}px / 4))`};
	}

	@media only screen and (min-width: 875px) and (max-width: 1298px) {
		grid-template-columns: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `repeat(2	,calc(${innerWidth - 342}px / 2))`
        : `repeat(3	,calc(${innerWidth - 36}px / 3))`};
	}

	@media only screen and (max-width: 875px)  {
		grid-template-columns: ${({areFiltersOpen, innerWidth, isMobile}) =>
    areFiltersOpen && !isMobile
        ? `repeat(1	,calc(${innerWidth - 342}px))`
        : `repeat(1,calc(${innerWidth - 36}px ))`};
		}


`;

export const StyledSourceImageBackground = styled.div<StyledSourceImageBackgroundProps>`
  background-size: cover;
  width: 100%;

  @media only screen and (min-width: 2150px) {
    height: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `calc(${innerWidth - 353}px / 6 / 1.6 / 2 )`
        : `calc(${innerWidth - 64}px / 7 / 1.6 / 2)`};
  }

  @media only screen and (min-width: 1725px) and (max-width: 2150px) {
    height: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `calc(${innerWidth - 353}px / 5 / 1.6 / 2)`
        : `calc(${innerWidth - 64}px / 6 / 1.6 / 2)`};
  }

  @media only screen and (min-width: 1470px) and (max-width: 1725px) {
    height: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `calc(${innerWidth - 353}px / 4 / 1.6 / 2)`
        : `calc(${innerWidth - 64}px / 5 / 1.6 / 2)`};
  }

  @media only screen and (min-width: 1298px) and (max-width: 1470px) {
    height: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `calc(${innerWidth - 353}px / 3 / 1.6 / 2)`
        : `calc(${innerWidth - 64}px / 4 / 1.6 / 2)`};
  }

  @media only screen and (min-width: 875px) and (max-width: 1298px) {
    height: ${({areFiltersOpen, innerWidth}) =>
    areFiltersOpen
        ? `calc(${innerWidth - 353}px / 2 / 1.6 / 2)`
        : `calc(${innerWidth - 64}px / 3 / 1.6 / 2)`};
  }

  @media only screen and (max-width: 875px) {
    height: ${({areFiltersOpen, innerWidth, isMobile}) =>
    areFiltersOpen && !isMobile
        ? `calc(${innerWidth - 353}px / 1.6 / 2)`
        : `calc(${innerWidth - 64}px / 1.6 / 2)`};
  }
`;
