import Logo from "../../icons/favicon.png";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import left from "../../icons/chevron-left.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { isValidEmail } from "../../utils/helpers";
import { ForgotPassword as FetchForgotPassword } from "../../utils/backend/SignIn SignUp/ForgotPassword";
import { asyncNotification } from "../../store/slice/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<{ email: string }>();

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    try {
      setError("root", {
        message: undefined,
      });
      const result = await FetchForgotPassword(data.email);
      if (result.isOk) {
        dispatch(
          asyncNotification({
            notification: "Email sent successfully.",
            isActive: true,
          })
        );
        navigate(`/SignIn/ResetPassword?email=${data.email}`);
        return;
      }

      switch (result.error) {
        case "Not Found": {
          setError("root", {
            message: "User with given email doesn't exist",
          });
          break;
        }
        default: {
          setError("root", {
            message: "Unexpected error, try again or contact us",
          });
        }
      }
    } catch (err) {
      setError("root", {
        message: "Unexpected error, try again or contact us",
      });
      console.error(err);
    }
  };

  const navigate = useNavigate();
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <div className={`${lightMode} reset-password-page`}>
      <img src={Logo} className={`${lightMode} uxmaps-logo sign-page`} />
      <div className={`${lightMode} reset-password`}>
        <div className={`${lightMode} top`}>
          <h1 className={`${lightMode} reset-password-title`}>
            Reset your password
          </h1>
          <h2 className={`${lightMode} wb`}>
            Welcome back! Please enter your details.
          </h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${lightMode} details`}>
            <label htmlFor="email" className={`${lightMode} label`}>
              Email
            </label>
            <input
              type="email"
              id="email"
              className={`${lightMode} email sign-in-input`}
              style={
                errors.email ? { marginBottom: 0 } : { marginBottom: "20px" }
              }
              placeholder="Enter your email"
              {...register("email", { required: true, validate: isValidEmail })}
            />
            {errors.email && (
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                  marginBottom: "20px",
                  fontWeight: 500,
                }}
              >
                {errors.email.message && errors.email.message?.length > 0
                  ? errors.email.message
                  : errors.email && errors.email.type === "required"
                  ? "Required"
                  : errors.email && errors.email.type === "validate"
                  ? "Not valid email"
                  : null}
              </div>
            )}
            <button
              className={`${lightMode} sign-btn up`}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? "Loading..." : "Reset password"}
            </button>
            <h2 className={`${lightMode} log-error`}>
              {errors.root && errors.root.message ? errors.root.message : null}
            </h2>
          </div>
        </form>

        <div className={`${lightMode} back-to-sign-up`}>
          <img src={left} alt="chevron-left" />
          <Link to="/SignIn">
            <h2>Back to</h2>
          </Link>
          <Link
            className={`${lightMode} sign-in-link`}
            to="/SignIn?active=login"
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
