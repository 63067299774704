import React from "react";
import { useAppSelector } from "../../store/hooks";
import checked from "../../icons/checkedLight.svg";
import checkedDark from "../../icons/checkedDark.svg";
import { PackageType } from "../SetupProfile/InitialBuyPlanPage";

const inclusionsPersonal = [
  "Browse up to 300 screens per month",
  "Limited search results",
  "One board",
  "One collection",
];

const inclusionsTeam = [
  "Browse up to 300 screens per month",
  "Limited search results",
  "One board",
  "One collection",
];

interface FreePlanProps {
  activePlanType: PackageType;
  isInitialPlanChoosing?: boolean;
  onGetStartedClicked?: () => void;
}

const FreePlan = ({
  activePlanType,
  isInitialPlanChoosing,
  onGetStartedClicked,
}: FreePlanProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <div className={`${lightMode} plan-card-container`}>
      <div className={`${lightMode} plan-card-header`} id={"free-header"}>
        <h1 className={`${lightMode} plan-card-header-large`}>Free</h1>
        <h2 className={`${lightMode} plan-card-header-small`}>
          USD $0{" "}
          <p className={`${lightMode} plan-card-header-small gray`}>
            per month
          </p>
        </h2>
      </div>
      <div className={`${lightMode} plan-card-inclusion`} id={"free-body"}>
        {activePlanType === "personal" &&
          inclusionsPersonal.map((e) => {
            return (
              <div className={`${lightMode} one-incl`}>
                {e !== "" && (
                  <img
                    src={lightMode === "light" ? checked : checkedDark}
                    alt={"checked personal"}
                  />
                )}
                <p style={{ margin: 0, height: "20px" }}>{e}</p>
              </div>
            );
          })}
        {activePlanType === "team" &&
          inclusionsTeam.map((e) => {
            return (
              <div className={`${lightMode} one-incl`}>
                {e !== "" && (
                  <img
                    src={lightMode === "light" ? checked : checkedDark}
                    alt={"checked team"}
                  />
                )}
                <p style={{ margin: 0, height: "20px" }}>{e}</p>
              </div>
            );
          })}
      </div>
      <div className={`${lightMode} plan-card-footer`}>
        <button
          className={`${lightMode} ${
            isInitialPlanChoosing
              ? "plan-card-button"
              : "plan-card-button-current-plan"
          }`}
          onClick={onGetStartedClicked}
          style={{
            cursor: isInitialPlanChoosing ? "pointer" : "default",
          }}
        >
          {!isInitialPlanChoosing ? "Your current plan" : "Start for free"}
        </button>
      </div>
    </div>
  );
};

export default FreePlan;
